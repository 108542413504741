import React, { useState } from 'react';
import './scss/SetChampionsApproval.scss';
import stake from './media/stake.png';

function SetChampionsApproval({
  allChampions,
  championsContract,
  tournamentContract,
  setChampionsToStakeGlobal,
  address,
  setInterfaceState
}) {

  const [championsToStake, setChampionsToStake] = useState([]);

  function toggleChampion(e) {
    const champId = Number(e.target.id)
    if (championsToStake.includes(champId)) {
      setChampionsToStake(
        championsToStake.filter(item =>
          item !== champId
        )
      );
    } else {
      setChampionsToStake([
        ...championsToStake,
        champId
      ]);
    }
  }

  const stakeChampions = async () => {
    // if there are selected champions to stake
    if (championsToStake.length > 0) {
      // get approval status from local storage
      let status = localStorage.getItem(address);
      // if approval has not yet been set
      if (status !== 'set') {
        try {
          console.log('set approval for Champions...')
          // set approval for all champions
          await championsContract.setApprovalForAll(tournamentContract.address, true);
          console.log('champions approval ✅', championsToStake)

          console.log('approval has been set')
          localStorage.setItem(address, 'set');

        } catch(err) {
          alert('Something went wrong. Try again');
        }
      } else {
        console.log('approval already set')
      }

      // create array for staking champions
      console.log('champions to stake are', championsToStake)
      setChampionsToStakeGlobal(championsToStake)
      document.getElementById('stakingAudio').play()
      setInterfaceState('stakeChampions')
    }
  }

  return (
    <div id="stake-container" className="wrapper">
      <div id="champions-container">
        {
          allChampions.map((item, index) =>
          <div
            className={`${championsToStake.includes(Number(item.token_id)) ? "staked" : "null"}`}
            key={index}
            onClick={toggleChampion}
          >
            <img
              src={item.image_url}
              id={item.token_id}
            />
          </div>
        )
      }
      </div>

      <button
        id="stake-button"
        className={`${championsToStake.length === 0 ? "null" : "enabled"}`}
        onClick={stakeChampions}
      >
        <img src={stake} />
      </button>
    </div>
  )
}

export default SetChampionsApproval;
