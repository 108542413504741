import React, { useContext, useState } from "react";
import YouLive from "../media/you-live.png";
import YouDied from "../media/you-died.png";
import Metadata from "../data/metadata.json";
import { HealthContext } from "../contexts/HealthContext";
import Modal from "react-modal";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const MatchResult = ({
  myTokenNumber,
  opponentTokenNumber,
  matchParticipantData,
  matchTitle,
}) => {
  const myData = Metadata.find((data) => {
    return data.id === myTokenNumber;
  });
  const opponentData = Metadata.find((data) => {
    return data.id === opponentTokenNumber;
  });
  const [isOpen, setIsOpen] = useState(false);
  const [yourChampChosen, setYourChampChosen] = useState(false);
  const { championHealthList, championAttributeList } =
    useContext(HealthContext);

  return (
    <>
      <h1 className="text-[#FD5839] text-5xl md:text-8xl font-['handwritting'] mb-5">
        {matchTitle}
      </h1>
      <Modal
        isOpen={isOpen}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.25)",
            display: "flex",
            zIndex: 99999,
          },
          content: {
            backgroundColor: "rgb(0, 0, 0)",
            display: "flex",
            flexDirection: "column",
          },
        }}
        onRequestClose={() => setIsOpen(false)}
        ariaHideApp={false}
      >
        <div className="flex flex-row">
          <div className="w-1/3"></div>
          <div className="w-1/3 flex flex-row justify-center">
            <h1 className="text-rose-500 text-8xl font-['handwritting']">
              Champion Attributes
            </h1>
          </div>
          <div className="flex flex-row justify-end w-1/3">
            <FontAwesomeIcon
              className="pt-6 w-1/3 flex flex-row justify-end text-white hover:cursor-pointer hover:text-rose-500"
              icon={faX}
              size="3x"
              onClick={() => setIsOpen(false)}
            />
          </div>
        </div>
        <div className="bg-black flex flex-col md:flex-row justify-center items-center flex-wrap">
          {yourChampChosen && championAttributeList.length > 0
            ? championAttributeList
                .find((champ) => {
                  return champ.id === myData.id;
                })
                .attributes.slice(0)
                .reverse()
                .map((attribute) => {
                  if (
                    attribute.trait_type !== "Ear" &&
                    attribute.trait_type !== "Type"
                  ) {
                    return (
                      <div className="flex flex-col items-center bg-[#A18900] border-white border-4 rounded-md m-10">
                        <h1 className="font-['handwritting'] text-black text-3xl m-2">
                          {attribute.trait_type}
                        </h1>
                        <h1 className="font-['handwritting'] text-black text-3xl m-2">
                          {attribute.value}
                        </h1>
                        {"health" in attribute ? (
                          <h1 className="font-['handwritting'] text-black text-3xl m-2">
                            Added HP: {attribute.health}
                          </h1>
                        ) : null}
                      </div>
                    );
                  } else if (attribute.trait_type === "Type") {
                    return (
                      <div className="flex flex-col items-center bg-[#A18900] border-white border-4 rounded-md m-10">
                        <h1 className="font-['handwritting'] text-black text-3xl m-2">
                          {attribute.trait_type}
                        </h1>
                        <h1 className="font-['handwritting'] text-black text-3xl m-2">
                          {attribute.value}
                        </h1>
                        {"health" in attribute ? (
                          <h1 className="font-['handwritting'] text-black text-3xl m-2">
                            Base HP: {attribute.health}
                          </h1>
                        ) : null}
                      </div>
                    );
                  } else {
                    return null;
                  }
                })
            : championAttributeList
                .find((champ) => {
                  return champ.id === opponentData.id;
                })
                .attributes.slice(0)
                .reverse()
                .map((attribute) => {
                  if (
                    attribute.trait_type !== "Ear" &&
                    attribute.trait_type !== "Type"
                  ) {
                    return (
                      <div className="flex flex-col items-center bg-[#A18900] border-white border-4 rounded-md m-10">
                        <h1 className="font-['handwritting'] text-black text-3xl m-2">
                          {attribute.trait_type}
                        </h1>
                        <h1 className="font-['handwritting'] text-black text-3xl m-2">
                          {attribute.value}
                        </h1>
                        {"health" in attribute ? (
                          <h1 className="font-['handwritting'] text-black text-3xl m-2">
                            Added HP: {attribute.health}
                          </h1>
                        ) : null}
                      </div>
                    );
                  } else if (attribute.trait_type === "Type") {
                    return (
                      <div className="flex flex-col items-center bg-[#A18900] border-white border-4 rounded-md m-10">
                        <h1 className="font-['handwritting'] text-black text-3xl m-2">
                          {attribute.trait_type}
                        </h1>
                        <h1 className="font-['handwritting'] text-black text-3xl m-2">
                          {attribute.value}
                        </h1>
                        {"health" in attribute ? (
                          <h1 className="font-['handwritting'] text-black text-3xl m-2">
                            Base HP: {attribute.health}
                          </h1>
                        ) : null}
                      </div>
                    );
                  } else {
                    return null;
                  }
                })}
        </div>
      </Modal>
      <div className="flex flex-col items-center xl:flex-row xl:justify-between">
        <div className="flex flex-col items-center bg-cover bg-center h-[350px] w-[420px] sm:h-[400px] sm:w-[470px] 2xl:h-[600px] 2xl:w-[720px] bg-[url('../media/you-bg.png')] xl:mr-10">
          <img
            src={myData.image}
            className="relative top-20 2xl:top-32 h-[40%] w-[40%]"
            alt="your champion"
          ></img>
          <h1 className="relative top-20 2xl:top-32 text-black text-3xl font-['handwritting']">
            {myData.name}
          </h1>
          <h1 className="relative top-20 2xl:top-32 text-black text-4xl font-['handwritting']">
            Health:{" "}
            {championHealthList.find((data) => {
              return data.id === myData.id;
            }) !== undefined
              ? championHealthList.find((data) => {
                  return data.id === myData.id;
                }).health
              : null}
          </h1>
          <h1
            className="relative top-20 2xl:top-32 text-black text-4xl font-['handwritting'] hover:cursor-pointer hover:underline"
            onClick={() => {
              setIsOpen(true);
              setYourChampChosen(true);
            }}
          >
            View Attributes
          </h1>
        </div>
        <h1 className="text-[#FD5839] text-8xl font-['handwritting']">VS</h1>
        <div className="flex flex-col items-center bg-cover bg-center h-[350px] w-[420px] sm:h-[400px] sm:w-[470px] 2xl:h-[600px] 2xl:w-[720px] bg-[url('../media/them-bg.png')] xl:ml-10">
          <img
            src={opponentData.image}
            className="relative top-20 2xl:top-32 h-[40%] w-[40%]"
            alt="their champion"
          ></img>
          <h1 className="relative top-20 2xl:top-32 text-black text-3xl font-['handwritting']">
            {opponentData.name}
          </h1>
          <h1 className="relative top-20 2xl:top-32 text-black text-4xl font-['handwritting']">
            Health:{" "}
            {championHealthList.find((data) => {
              return data.id === opponentData.id;
            }) !== undefined
              ? championHealthList.find((data) => {
                  return data.id === opponentData.id;
                }).health
              : null}
          </h1>
          <h1
            className="relative top-20 2xl:top-32 text-black text-4xl font-['handwritting'] hover:cursor-pointer hover:underline"
            onClick={() => {
              setIsOpen(true);
              setYourChampChosen(false);
            }}
          >
            View Attributes
          </h1>
        </div>
      </div>
      <div className="flex flex-row justify-center">
        {matchParticipantData.find((participant) => {
          return myTokenNumber === parseInt(participant.token.split("#")[1]);
        }).isWinner ? (
          <img src={YouLive} alt="you live" className="w-2/5 mt-10"></img>
        ) : matchParticipantData.find((participant) => {
            return myTokenNumber === parseInt(participant.token.split("#")[1]);
          }).isWinner === false ? (
          <img src={YouDied} alt="you died" className="w-2/5 mt-10"></img>
        ) : null}
      </div>
    </>
  );
};

export default MatchResult;
