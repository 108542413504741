import mintingOgg from "./media/buzzards.ogg";
import mintingMp3 from "./media/buzzards.mp3";
import stakingOgg from "./media/pancake.ogg";
import stakingMp3 from "./media/pancake.mp3";
import tournamentMp3 from "./media/tournament.mp3";

function Audio() {
  return (
    <>
      <audio id="mintingAudio" loop>
        <source src={mintingOgg} type="audio/ogg" />
        <source src={mintingMp3} type="audio/mpeg" />
        Your browser does not support the audio tag.
      </audio>
      <audio id="stakingAudio" loop>
        <source src={stakingOgg} type="audio/ogg" />
        <source src={stakingMp3} type="audio/mpeg" />
        Your browser does not support the audio tag.
      </audio>
      <audio id="tournamentAudio" loop>
        <source src={tournamentMp3} type="audio/mpeg" />
        Your browser does not support the audio tag.
      </audio>
    </>
  );
}

export default Audio;
