import { createContext, useEffect, useState } from "react";
//import RoundData from "../data/round-data.json";
import uuid from "uuid-random";
import axios from "axios";
import Metadata from "../data/metadata.json";

export const BracketContext = createContext();

const BracketContextProvider = ({ children }) => {
  const [bracketOneData, setBracketOneData] = useState([]);
  const [bracketTwoData, setBracketTwoData] = useState([]);
  const [bracketThreeData, setBracketThreeData] = useState([]);
  const [bracketFourData, setBracketFourData] = useState([]);
  const [finalFourData, setFinalFourData] = useState([]);
  const [playInData, setPlayInData] = useState([]);

  // Function used to fill upcoming match data with TBA
  // we need this TBA data for the bracket library to work
  const fillMissingBracketData = (
    bracketData,
    participantCount,
    nextRoundIndex
  ) => {
    while (participantCount > 4) {
      let idOffset = 0;
      let nextMatchIdOffset = 0;
      for (let bracketIndex = 0; bracketIndex < 4; bracketIndex++) {
        for (
          let currentParticipantCount = 0;
          currentParticipantCount < participantCount / 4;
          currentParticipantCount += 2
        ) {
          bracketData[bracketIndex].push({
            id: 10000 * nextRoundIndex + idOffset,
            nextMatchId:
              participantCount !== 8
                ? 10000 * (nextRoundIndex + 1) + nextMatchIdOffset
                : null,
            tournamentRoundText: `${nextRoundIndex + 1}`,
            matchText: `Round ${nextRoundIndex + 1}`,
            participants: [
              {
                name: "TBA",
              },
              {
                name: `TBA`,
              },
            ],
          });
          idOffset += 1;
          currentParticipantCount % 4 !== 0
            ? (nextMatchIdOffset += 1)
            : (nextMatchIdOffset += 0);
        }
      }
      nextRoundIndex += 1;
      participantCount /= 2;
    }
  };

  useEffect(() => {
    let bracketData = [[], [], [], []];
    let tokenIdIdentifiers = {};
    let finalsData = [];

    const setFinalFourBracket = async (finalFourData) => {
      try {
        const res = await axios.get("/.netlify/functions/tournament-data");
        const roundData = res.data;
        let tokenIdIdentifiers = {};
        if (roundData.finalFourData !== undefined) {
          tokenIdIdentifiers[`${roundData.finalFourData.round_data[0]}`] =
            uuid();
          tokenIdIdentifiers[`${roundData.finalFourData.round_data[1]}`] =
            uuid();
          tokenIdIdentifiers[`${roundData.finalFourData.round_data[2]}`] =
            uuid();
          tokenIdIdentifiers[`${roundData.finalFourData.round_data[3]}`] =
            uuid();
          finalFourData.push({
            id: 1,
            nextMatchId: 3,
            tournamentRoundText: "Semifinals",
            matchText: "Semifinals",
            participants: [
              {
                id: tokenIdIdentifiers[
                  `${roundData.finalFourData.round_data[0]}`
                ],
                resultText:
                  roundData.finalData !== undefined &&
                  roundData.finalData.round_data.includes(
                    roundData.finalFourData.round_data[0]
                  )
                    ? "Won"
                    : roundData.finalData !== undefined
                    ? "Lost"
                    : null,
                isWinner:
                  roundData.finalData !== undefined
                    ? roundData.finalData.round_data.includes(
                        roundData.finalFourData.round_data[0]
                      )
                    : null,
                name:
                  Metadata.find((data) => {
                    return data.id === roundData.finalFourData.round_data[0];
                  }) !== undefined
                    ? Metadata.find((data) => {
                        return (
                          data.id === roundData.finalFourData.round_data[0]
                        );
                      }).name
                    : null,
                token: `Token #${roundData.finalFourData.round_data[0]}`
              },
              {
                id: tokenIdIdentifiers[
                  `${roundData.finalFourData.round_data[1]}`
                ],
                resultText:
                  roundData.finalData !== undefined &&
                  roundData.finalData.round_data.includes(
                    roundData.finalFourData.round_data[1]
                  )
                    ? "Won"
                    : roundData.finalData !== undefined
                    ? "Lost"
                    : null,
                isWinner:
                  roundData.finalData !== undefined
                    ? roundData.finalData.round_data.includes(
                        roundData.finalFourData.round_data[1]
                      )
                    : null,
                name:
                  Metadata.find((data) => {
                    return data.id === roundData.finalFourData.round_data[1];
                  }) !== undefined
                    ? Metadata.find((data) => {
                        return (
                          data.id === roundData.finalFourData.round_data[1]
                        );
                      }).name
                    : null,
                token: `Token #${roundData.finalFourData.round_data[1]}`
              },
            ],
          });
          finalFourData.push({
            id: tokenIdIdentifiers[`${roundData.finalFourData.round_data[2]}`],
            nextMatchId: 3,
            tournamentRoundText: "Semifinals",
            matchText: "Semifinals",
            participants: [
              {
                id: 3,
                resultText:
                  roundData.finalData !== undefined &&
                  roundData.finalData.round_data.includes(
                    roundData.finalFourData.round_data[2]
                  )
                    ? "Won"
                    : roundData.finalData !== undefined
                    ? "Lost"
                    : null,
                isWinner:
                  roundData.finalData !== undefined
                    ? roundData.finalData.round_data.includes(
                        roundData.finalFourData.round_data[2]
                      )
                    : null,
                name:
                  Metadata.find((data) => {
                    return data.id === roundData.finalFourData.round_data[2];
                  }) !== undefined
                    ? Metadata.find((data) => {
                        return (
                          data.id === roundData.finalFourData.round_data[2]
                        );
                      }).name
                    : null,
                token: `Token #${roundData.finalFourData.round_data[2]}`
              },
              {
                id: tokenIdIdentifiers[
                  `${roundData.finalFourData.round_data[3]}`
                ],
                resultText:
                  roundData.finalData !== undefined &&
                  roundData.finalData.round_data.includes(
                    roundData.finalFourData.round_data[3]
                  )
                    ? "Won"
                    : roundData.finalData !== undefined
                    ? "Lost"
                    : null,
                isWinner:
                  roundData.finalData !== undefined
                    ? roundData.finalData.round_data.includes(
                        roundData.finalFourData.round_data[3]
                      )
                    : null,
                name:
                  Metadata.find((data) => {
                    return data.id === roundData.finalFourData.round_data[3];
                  }) !== undefined
                    ? Metadata.find((data) => {
                        return (
                          data.id === roundData.finalFourData.round_data[3]
                        );
                      }).name
                    : null,
                token: `Token #${roundData.finalFourData.round_data[3]}`
              },
            ],
          });
        }
        if (roundData.finalData !== undefined) {
          finalFourData.push({
            id: 3,
            nextMatchId: null,
            tournamentRoundText: "Finals",
            matchText: "Finals",
            participants: [
              {
                id: tokenIdIdentifiers[`${roundData.finalData.round_data[0]}`],
                resultText:
                  roundData.winnerData !== undefined &&
                  roundData.winnerData.round_data.includes(
                    roundData.finalData.round_data[0]
                  )
                    ? "Won"
                    : roundData.winnerData !== undefined
                    ? "Lost"
                    : null,
                isWinner:
                  roundData.winnerData !== undefined
                    ? roundData.winnerData.round_data.includes(
                        roundData.finalData.round_data[0]
                      )
                    : null,
                name:
                  Metadata.find((data) => {
                    return data.id === roundData.finalData.round_data[0];
                  }) !== undefined
                    ? Metadata.find((data) => {
                        return data.id === roundData.finalData.round_data[0];
                      }).name
                    : null,
                token: `Token #${roundData.finalData.round_data[0]}`
              },
              {
                id: tokenIdIdentifiers[`${roundData.finalData.round_data[1]}`],
                resultText:
                  roundData.winnerData !== undefined &&
                  roundData.winnerData.round_data.includes(
                    roundData.finalData.round_data[1]
                  )
                    ? "Won"
                    : roundData.winnerData !== undefined
                    ? "Lost"
                    : null,
                isWinner:
                  roundData.winnerData !== undefined
                    ? roundData.winnerData.round_data.includes(
                        roundData.finalData.round_data[1]
                      )
                    : null,
                name:
                  Metadata.find((data) => {
                    return data.id === roundData.finalData.round_data[1];
                  }) !== undefined
                    ? Metadata.find((data) => {
                        return data.id === roundData.finalData.round_data[1];
                      }).name
                    : null,
                token: `Token #${roundData.finalData.round_data[1]}`
              },
            ],
          });
        } else if (roundData.finalFourData !== undefined) {
          finalFourData.push({
            id: 3,
            nextMatchId: null,
            tournamentRoundText: "Finals",
            matchText: "Finals",
            participants: [
              {
                name: "TBA",
              },
              {
                name: `TBA`,
              },
            ],
          });
        }
      } catch (err) {
        console.log(err);
      }
    };

    // Function fetches tournament data from database and creates
    // the tournament bracket data structure so the bracket library can use
    const setBracketData = async (bracketData) => {
      try {
        const res = await axios.get("/.netlify/functions/tournament-data");
        const roundData = res.data.roundData;
        let participantCount =
          roundData.length >= 1 &&
          roundData[0]["round1"].length >= 1 &&
          roundData[0]["round1"][0].length
            ? roundData[0]["round1"][0].length * 4
            : 0;

        roundData.forEach((round, roundIndex) => {
          let idOffset = 0;
          let nextMatchIdOffset = 0;

          round[`round${roundIndex + 1}`].forEach((bracket, bracketIndex) => {
            for (let i = 0; i < bracket.length; i += 2) {
              if (bracket.length === 1) {
                break;
              }

              if (roundIndex === 0) {
                tokenIdIdentifiers[bracket[i]] = uuid();
                tokenIdIdentifiers[bracket[i + 1]] = uuid();
              }

              bracketData[bracketIndex].push({
                id: 10000 * roundIndex + idOffset,
                nextMatchId:
                  bracket.length !== 2
                    ? 10000 * (roundIndex + 1) + nextMatchIdOffset
                    : null,
                tournamentRoundText: `${roundIndex + 1}`,
                matchText: `Round ${roundIndex + 1}`,
                participants: [
                  {
                    id: tokenIdIdentifiers[bracket[i]],
                    resultText:
                      roundIndex + 1 < roundData.length &&
                      roundData[roundIndex + 1][`round${roundIndex + 2}`][
                        bracketIndex
                      ].includes(
                        roundData[roundIndex][`round${roundIndex + 1}`][
                          bracketIndex
                        ][i]
                      )
                        ? "Won"
                        : roundIndex + 1 < roundData.length
                        ? "Lost"
                        : null,
                    isWinner:
                      roundIndex + 1 < roundData.length
                        ? roundData[roundIndex + 1][`round${roundIndex + 2}`][
                            bracketIndex
                          ].includes(
                            roundData[roundIndex][`round${roundIndex + 1}`][
                              bracketIndex
                            ][i]
                          )
                        : null,
                    name:
                      Metadata.find((data) => {
                        return data.id === bracket[i];
                      }) !== undefined
                        ? Metadata.find((data) => {
                            return data.id === bracket[i];
                          }).name
                        : null,
                    token: `Token #${bracket[i]}`
                  },
                  {
                    id: tokenIdIdentifiers[bracket[i + 1]],
                    resultText:
                      roundIndex + 1 < roundData.length &&
                      roundData[roundIndex + 1][`round${roundIndex + 2}`][
                        bracketIndex
                      ].includes(
                        roundData[roundIndex][`round${roundIndex + 1}`][
                          bracketIndex
                        ][i + 1]
                      )
                        ? "Won"
                        : roundIndex + 1 < roundData.length
                        ? "Lost"
                        : null,
                    isWinner:
                      roundIndex + 1 < roundData.length
                        ? roundData[roundIndex + 1][`round${roundIndex + 2}`][
                            bracketIndex
                          ].includes(
                            roundData[roundIndex][`round${roundIndex + 1}`][
                              bracketIndex
                            ][i + 1]
                          )
                        : null,
                    name:
                      Metadata.find((data) => {
                        return data.id === bracket[i + 1];
                      }) !== undefined
                        ? Metadata.find((data) => {
                            return data.id === bracket[i + 1];
                          }).name
                        : null,
                    token: `Token #${bracket[i + 1]}`
                  },
                ],
              });

              idOffset += 1;
              i % 4 !== 0 ? (nextMatchIdOffset += 1) : (nextMatchIdOffset += 0);
            }
          });
          if (participantCount !== 0) {
            participantCount /= 2;
          }
        });
        // Fill rest of bracket data with TBA matches if needed
        fillMissingBracketData(bracketData, participantCount, roundData.length);
        setPlayInData(res.data.playInData.round_data);
      } catch (err) {
        console.log(err);
      }
    };
    setBracketData(bracketData);
    setFinalFourBracket(finalsData);
    setBracketOneData(bracketData[0]);
    setBracketTwoData(bracketData[1]);
    setBracketThreeData(bracketData[2]);
    setBracketFourData(bracketData[3]);
    setFinalFourData(finalsData);
  }, []);

  return (
    <BracketContext.Provider
      value={{
        bracketOneData,
        bracketTwoData,
        bracketThreeData,
        bracketFourData,
        finalFourData,
        playInData,
      }}
    >
      {children}
    </BracketContext.Provider>
  );
};

export default BracketContextProvider;
