import Item from './Item';
import './scss/Collection.scss';

function Collection({
  allItems,
  selectedItem,
  id,
  prev,
  next,
  label
}) {

  return (
    <>
      <div className={`switcher ${allItems.length > 1 ? "on" : ""}`} id={id}>
        <div className={`prevBtn ${selectedItem > 0 ? 'on' : ""}`} onClick={prev}></div>
        <div className="avatarContainer">
          <div className="frame"></div>
          {
            // map howlerz
            allItems.map((item, index) =>
              <Item
                image={item.image_url}
                key={item.token_id}
                id={item.token_id}
                visible={index === selectedItem ? 'on' : 'off'}
              />
            )
          }
        </div>
        <div className={`nextBtn ${allItems.length > 1 && selectedItem !== allItems.length - 1 ? "on" : ""}`} onClick={next}></div>
      </div>
      <img src={label} className="label" />
    </>
  )
}

export default Collection;
