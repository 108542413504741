// Libraries
import React, { useState } from 'react';
// Components
import Collection from './Collection';
// Styles
import './scss/SetApproval.scss';
// Media
import wolfLabel from './media/wolf-label.png';
import preyLabel from './media/prey-label.png';
import feed from './media/feed.png';
import feedDisclaimer from './media/feed-disclaimer.png';

function SetApproval({
  allHowlerz,
  allPrey,
  address,
  howlerzContract,
  preyContract,
  championsContract,
  setTxnObj,
  setInterfaceState
}) {

  const [selectedHowler, setSelectedHowler] = useState(0);
  const [selectedPrey, setSelectedPrey] = useState(0);

  function prev(e) {
    if (e.target.parentNode.id === 'howlerzColl') {
      setSelectedHowler(selectedHowler - 1);
    } else {
      setSelectedPrey(selectedPrey - 1);
    }
  }

  function next(e) {
    if (e.target.parentNode.id === 'howlerzColl') {
      setSelectedHowler(selectedHowler + 1);
    } else {
      setSelectedPrey(selectedPrey + 1);
    }
  }

  const unlockNFTs = async () => {
    const howlerForTxn = allHowlerz[selectedHowler];
    const preyForTxn = allPrey[selectedPrey];
    let status = localStorage.getItem('howlerz');

    if (status !== 'set') {
      try {
        // approve howler
        await howlerzContract.setApprovalForAll(championsContract.address, true);
        console.log('howlerz approval ✅', howlerForTxn.id)
        // approve prey
        await preyContract.setApprovalForAll(championsContract.address, true);
        console.log('prey approval ✅', preyForTxn.id)

        console.log('approval has been set')
        localStorage.setItem('howlerz', 'set');

      } catch(err) {
        alert('Something went wrong. Try again');
      }
    } else {
      console.log('approval already set')
    }

    console.log('set transaction object')
    setTxnObj({
      "howler": howlerForTxn.token_id,
      "food": preyForTxn.token_id,
      "foodstatus": preyForTxn.feedableHowler ? 1 : 0,
      "address": address
    })

    document.getElementById('mintingAudio').play()
    setInterfaceState('minting')
  }

  return (
    <div id="feed-container" className="wrapper">
      <div id="collections-container">
        <div id="howlerzContainer">
          <Collection
            allItems={allHowlerz}
            selectedItem={selectedHowler}
            id={'howlerzColl'}
            prev={prev}
            next={next}
            label={wolfLabel}
          />
        </div>

        <div id="preyContainer">
          <Collection
            allItems={allPrey}
            selectedItem={selectedPrey}
            id={'preyColl'}
            prev={prev}
            next={next}
            label={preyLabel}
          />
        </div>
      </div>

      <button id="feed-button" onClick={unlockNFTs}>
        <img src={feed} />
      </button>

      <img id="feed-disclaimer" src={feedDisclaimer} />
    </div>
  )
}

export default SetApproval;
