// Libraries
import { useEffect } from "react";
import { useWeb3Modal } from "@web3modal/react";
import { useAccount } from "wagmi";
// Contracts
import {
  howlerzContractAddress,
  preyContractAddress,
  championsContractAddress,
  tournamentContractAddress,
} from "./contracts/contractAddresses";
import howlerzABI from "./contracts/howlerzABI.json";
import preyABI from "./contracts/preyABI.json";
import championsABI from "./contracts/championsABI.json";
import tournamentABI from "./contracts/tournamentABI.json";
// Styles
import "./scss/Connect.scss";
// Media
import connectImg from "./media/connect-wallet.png";

function Connect({ setWeb3Obj, setInterfaceState }) {
  const { open } = useWeb3Modal();
  const { address, isConnected } = useAccount();
  const { ethers } = require("ethers");

  useEffect(() => {
    if (isConnected) {
      console.log("Connected", address);
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const localprovider = new ethers.providers.JsonRpcProvider(
        "http://127.0.0.1:8545"
      );
      const signer = provider.getSigner();
      const howlerzContract = new ethers.Contract(
        howlerzContractAddress,
        howlerzABI,
        signer
      );
      const preyContract = new ethers.Contract(
        preyContractAddress,
        preyABI,
        signer
      );
      const championsContract = new ethers.Contract(
        championsContractAddress,
        championsABI,
        signer
      );
      const tournamentContract = new ethers.Contract(
        tournamentContractAddress,
        tournamentABI,
        signer
      );

      setWeb3Obj({
        address,
        provider,
        signer,
        howlerzContract,
        preyContract,
        championsContract,
        tournamentContract,
      });

      setInterfaceState("connected");
    }
  });

  return (
    <div id="connect-container" className="wrapper">
      <button id="connect-button" onClick={open}>
        <img src={connectImg} />
      </button>
    </div>
  );
}

export default Connect;
