import './scss/Champion.scss';
import behold from './media/behold.png';
import seeYou from './media/see-you.png'

function Champion({ champ }) {

  return (
    <div id="champion" className="wrapper">
      <div className="champion-fade" id="champ-overlay"></div>
      <img id="behold" src={behold} />
      <img className="champion-fade" id="champ-img" src={champ.image} />
      <p className="champion-fade" id="champ-name">{champ.name}</p>
      <img id="see-you" className="champion-fade" src={seeYou} />
    </div>
  )
}

export default Champion;
