// Libraries
import React, { useState, useEffect } from "react";
// Components
import Header from "./Header";
import Audio from "./Audio";
import Connect from "./Connect";
import BurnOrStake from "./BurnOrStake";
import CheckForHowlerz from "./CheckForHowlerz";
import SetApproval from "./SetApproval";
import MintChampion from "./MintChampion";
import Champion from "./Champion";
import CheckForChampions from "./CheckForChampions";
import SetChampionsApproval from "./SetChampionsApproval";
import StakeChampions from "./StakeChampions";
import Staked from "./Staked";
import Tournament from "./screens/tournament";
import MyBattles from "./screens/MyBattles";
// Styles
import "./scss/App.scss";

function App() {
  const [web3Obj, setWeb3Obj] = useState();
  const [allHowlerz, setAllHowlerz] = useState([]);
  const [allPrey, setAllPrey] = useState([]);
  const [allChampions, setAllChampions] = useState([]);
  const [txnObj, setTxnObj] = useState({});
  const [champ, setChamp] = useState();
  const [championsToStakeGlobal, setChampionsToStakeGlobal] = useState([]);
  const [interfaceState, setInterfaceState] = useState("initial");

  useEffect(() => {
    if (window.location.pathname === "/tournament") {
      setInterfaceState("tournament");
    }
  }, []);

  // manage which component is rendered based on interfaceState
  const renderMainComponent = () => {
    var component;
    if (interfaceState === "initial") {
      component = (
        <Connect
          setWeb3Obj={setWeb3Obj}
          setInterfaceState={setInterfaceState}
        />
      );
    } else if (interfaceState === "connected") {
      component = <BurnOrStake setInterfaceState={setInterfaceState} />;
      const regiteredChamps = web3Obj.championsContract.getRegistered();
      regiteredChamps.then((res) => console.log(res));
    } else if (interfaceState === "checkForHowlerz") {
      component = (
        <CheckForHowlerz
          address={web3Obj.address}
          howlerzContract={web3Obj.howlerzContract}
          preyContract={web3Obj.preyContract}
          allHowlerz={allHowlerz}
          setAllHowlerz={setAllHowlerz}
          allPrey={allPrey}
          setAllPrey={setAllPrey}
          setInterfaceState={setInterfaceState}
        />
      );
    } else if (interfaceState === "setApproval") {
      component = (
        <SetApproval
          allHowlerz={allHowlerz}
          allPrey={allPrey}
          address={web3Obj.address}
          howlerzContract={web3Obj.howlerzContract}
          preyContract={web3Obj.preyContract}
          championsContract={web3Obj.championsContract}
          setTxnObj={setTxnObj}
          setInterfaceState={setInterfaceState}
        />
      );
    } else if (interfaceState === "minting") {
      component = (
        <MintChampion
          txnObj={txnObj}
          championsContract={web3Obj.championsContract}
          setChamp={setChamp}
          setInterfaceState={setInterfaceState}
        />
      );
    } else if (interfaceState === "champion") {
      component = <Champion champ={champ} />;
    } else if (interfaceState === "checkForChampions") {
      component = (
        <CheckForChampions
          address={web3Obj.address}
          championsContract={web3Obj.championsContract}
          setAllChampions={setAllChampions}
          setInterfaceState={setInterfaceState}
        />
      );
    } else if (interfaceState === "setChampionsApproval") {
      component = (
        <SetChampionsApproval
          allChampions={allChampions}
          setInterfaceState={setInterfaceState}
          championsContract={web3Obj.championsContract}
          tournamentContract={web3Obj.tournamentContract}
          address={web3Obj.address}
          setChampionsToStakeGlobal={setChampionsToStakeGlobal}
        />
      );
    } else if (interfaceState === "stakeChampions") {
      component = (
        <StakeChampions
          championsToStakeGlobal={championsToStakeGlobal}
          tournamentContract={web3Obj.tournamentContract}
          setInterfaceState={setInterfaceState}
        />
      );
    } else if (interfaceState === "staked") {
      component = <Staked />;
    } else if (interfaceState === "tournament") {
      component = <Tournament setInterfaceState={setInterfaceState} />;
    } else if (interfaceState === "mybattles") {
      component = <MyBattles setInterfaceState={setInterfaceState} />
    }
    return component;
  };

  return (
    <div id="app">
      <div
        id="bg"
        className="bg-[url('../media/mobile-tournament-background.jpeg')] md:bg-[url('../media/tournament-bg.png')]"
      ></div>
      <Header img={interfaceState === "stake" ? "stake" : null} />
      <Audio />
      <main
        id={`${
          interfaceState !== "tournament"
            ? "main-component"
            : "tournament-component"
        }`}
      >
        {renderMainComponent()}
      </main>
    </div>
  );
}

export default App;
