import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import {
  EthereumClient,
  modalConnectors,
  walletConnectProvider,
} from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/react";
import { configureChains, createClient } from "wagmi";
import { mainnet, goerli } from "wagmi/chains";
import BracketContextProvider from "./contexts/BracketContext";
import PlayInContextProvider from "./contexts/PlayInContext";
import HealthContextProvider from "./contexts/HealthContext";

// Web3 config
const chains = [mainnet];
// Wagmi client
const { provider } = configureChains(chains, [
  walletConnectProvider({ projectId: process.env.REACT_APP_WALLET_CONNECT }),
]);
const wagmiClient = createClient({
  autoConnect: true,
  connectors: modalConnectors({ appName: "web3Modal", chains }),
  provider,
});
// Web3Modal Ethereum Client
const ethereumClient = new EthereumClient(wagmiClient, chains);

ReactDOM.render(
  <React.StrictMode>
    <PlayInContextProvider>
      <BracketContextProvider>
        <HealthContextProvider>
          <App />
          <Web3Modal
            projectId={process.env.REACT_APP_WALLET_CONNECT}
            ethereumClient={ethereumClient}
          />
        </HealthContextProvider>
      </BracketContextProvider>
    </PlayInContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
