// Libraries
import { useEffect, useState } from 'react';
// Styles
import './scss/CheckForHowlerz.scss';
// Media
import checking from './media/checking.png';

function CheckForHowlerz({
  address,
  howlerzContract,
  preyContract,
  allHowlerz,
  setAllHowlerz,
  allPrey,
  setAllPrey,
  setInterfaceState
}) {

  const [count, setCount] = useState(0);

  useEffect(() => {
    checkForHowlerz()
    setInterfaceState('setApproval')
  }, [count]);

  const feedableHowlerz = ['340', '1796', '1872', '3759'];
  const nonFeedablePrey = ['2989', '1350', '2682', '3696'];

  // lookup tokens for connected account (using Moralis)
  const checkForHowlerz = async () => {
    const options = {
      method: 'GET',
      headers: {
        accept: 'application/json',
        'X-API-KEY': process.env.REACT_APP_SIMPLEHASH
      }
    };

    fetch(`https://api.simplehash.com/api/v0/nfts/owners?chains=polygon,ethereum&wallet_addresses=${address}&contract_addresses=${howlerzContract.address}&limit=50`, options)
      .then(response => response.json())
      .then(response => {
        if (response.nfts.length > 0) {
          response.nfts.forEach((item) => {
            if (feedableHowlerz.includes(item.token_id)) {
              item.feedableHowler = true;
              setAllPrey(allPrey => [...allPrey, item]);
            } else {
              setAllHowlerz(allHowlerz => [...allHowlerz, item]);
            }
          })
          setCount(count + 1)
        } else {
          alert('You need both a Howler and a Prey in order to feed. Disconnect this account then refresh the site to try again with a different account.')
        }
      })
      .catch(err => {
        console.error(err)
      });

      fetch(`https://api.simplehash.com/api/v0/nfts/owners?chains=polygon,ethereum&wallet_addresses=${address}&contract_addresses=${preyContract.address}&limit=50`, options)
        .then(response => response.json())
        .then(response => {
          if (response.nfts.length > 0) {
            response.nfts.forEach((item) => {
              setAllPrey(allPrey => [...allPrey, item]);
            })
            setCount(count + 1)
          } else {
            alert('You need both a Howler and a Prey in order to feed. Disconnect this account then refresh the site to try again with a different account.')
          }
        })
        .catch(err => console.error(err));
  }

  return (
    <div id="checking-container" className="wrapper">
      <img src={checking} />
    </div>
  )
}

export default CheckForHowlerz;
