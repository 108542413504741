import React, { useContext, useEffect, useState } from "react";
import {
  SingleEliminationBracket,
  Match,
} from "@g-loot/react-tournament-brackets";
import { useWindowSize } from "@react-hook/window-size";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { BracketContext } from "../contexts/BracketContext";
import { ErrorBoundary } from "react-error-boundary";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import PlayInList from "./PlayInList";
import MyBattles from "../media/your-battles.png";
import Metadata from "../data/metadata.json";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "react-modal";
import { HealthContext } from "../contexts/HealthContext";

const bracketHeaders = [
  { value: "play-in", label: "Play In Matches" },
  { value: "bracket-1", label: "Bracket 1" },
  { value: "bracket-2", label: "Bracket 2" },
  { value: "bracket-3", label: "Bracket 3" },
  { value: "bracket-4", label: "Bracket 4" },
  { value: "final-four", label: "Final Four Bracket" },
];

const BracketTab = ({
  matches,
  isModalOpen,
  setSelectedBottomParty,
  setSelectedTopParty,
  setIsModalOpen,
}) => {
  return (
    <ErrorBoundary
      FallbackComponent={() => {
        return (
          <div className="flex flex-row justify-center text-[#FD5839] text-5xl font-['handwritting']">
            No Match Data Found!
          </div>
        );
      }}
    >
      <div className="flex flex-row overflow-scroll">
        <SingleEliminationBracket
          matches={matches}
          matchComponent={({
            bottomHovered,
            bottomParty,
            bottomText,
            bottomWon,
            match,
            onMatchClick,
            onMouseEnter,
            onMouseLeave,
            onPartyClick,
            topHovered,
            topParty,
            topText,
            topWon,
          }) => {
            return Match({
              bottomHovered,
              bottomParty: {
                ...bottomParty,
                name: (
                  <div className="flex flex-row justify-between">
                    <img
                      src={
                        Metadata.find((data) => {
                          return data.name === bottomParty.name;
                        }) !== undefined
                          ? Metadata.find((data) => {
                              return data.name === bottomParty.name;
                            }).image
                          : ""
                      }
                      className="h-[18%] w-[18%]"
                    ></img>
                    <div className="flex flex-col justify-center">
                      <h1 className="mr-10">{bottomParty.name}</h1>
                    </div>
                  </div>
                ),
              },
              bottomText,
              bottomWon,
              match,
              onMatchClick,
              onMouseEnter,
              onMouseLeave,
              onPartyClick: () => {
                setIsModalOpen(true);
                setSelectedBottomParty(bottomParty);
                setSelectedTopParty(topParty);
              },
              topHovered,
              topParty: {
                ...topParty,
                name: (
                  <div className="flex flex-row justify-between">
                    <img
                      src={
                        Metadata.find((data) => {
                          return data.name === topParty.name;
                        }) !== undefined
                          ? Metadata.find((data) => {
                              return data.name === topParty.name;
                            }).image
                          : ""
                      }
                      className="h-[18%] w-[18%]"
                    ></img>
                    <div className="flex flex-col justify-center">
                      <h1 className="mr-10">{topParty.name}</h1>
                    </div>
                  </div>
                ),
              },
              topText,
              topWon,
            });
          }}
        />
      </div>
    </ErrorBoundary>
  );
};

const TournamentBrackets = ({ setInterfaceState }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTopParty, setSelectedTopParty] = useState(null);
  const [selectedBottomParty, setSelectedBottomParty] = useState(null);
  const {
    bracketOneData,
    bracketTwoData,
    bracketThreeData,
    bracketFourData,
    finalFourData,
  } = useContext(BracketContext);
  const { championHealthList, championAttributeList } =
    useContext(HealthContext);

  const handleDropdownSelect = ({ value }) => {
    switch (value) {
      case "play-in":
        setActiveTab(0);
        break;
      case "bracket-1":
        setActiveTab(1);
        break;
      case "bracket-2":
        setActiveTab(2);
        break;
      case "bracket-3":
        setActiveTab(3);
        break;
      case "bracket-4":
        setActiveTab(4);
        break;
      case "final-four":
        setActiveTab(5);
        break;
      default:
        setActiveTab(0);
    }
  };

  return (
    <div className="bg-black z-50 w-full">
      <Modal
        isOpen={isModalOpen}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.25)",
            display: "flex",
            zIndex: 99999,
          },
          content: {
            backgroundColor: "rgb(0, 0, 0)",
            display: "flex",
            flexDirection: "column",
          },
        }}
        onRequestClose={() => setIsModalOpen(false)}
        ariaHideApp={false}
      >
        <div className="flex flex-row">
          <div className="w-1/3"></div>
          <div className="w-1/3 flex flex-row justify-center">
            <h1 className="text-rose-500 text-8xl font-['handwritting']">
              Here are your challengers
            </h1>
          </div>
          <div className="flex flex-row justify-end w-1/3">
            <FontAwesomeIcon
              className="pt-6 w-1/3 flex flex-row justify-end text-white hover:cursor-pointer hover:text-rose-500"
              icon={faX}
              size="3x"
              onClick={() => setIsModalOpen(false)}
            />
          </div>
        </div>
        <div className="flex flex-col items-center xl:flex-row xl:justify-evenly">
          <div className="flex flex-col items-center w-1/3">
            <div className="flex flex-col items-center bg-cover bg-center h-[420px] w-[380px] sm:h-[470px] sm:w-[470px] 2xl:h-[780px] 2xl:w-[720px] bg-[url('../media/card.png')] xl:mr-10">
              <img
                src={
                  selectedTopParty !== null &&
                  Metadata.find((data) => {
                    return data.name === selectedTopParty.name;
                  }) !== undefined
                    ? Metadata.find((data) => {
                        return data.name === selectedTopParty.name;
                      }).image
                    : ""
                }
                className="relative top-20 2xl:top-36 h-[40%] w-[40%]"
                alt="your champion"
              ></img>
              <h1 className="relative top-20 2xl:top-36 text-black text-6xl font-['handwritting']">
                {selectedTopParty !== null ? selectedTopParty.name : ""}
              </h1>
              <h1 className="relative top-20 2xl:top-36 text-black text-5xl font-['handwritting']">
                Health:{" "}
                {selectedTopParty !== null &&
                championHealthList.find((champ) => {
                  return champ.name === selectedTopParty.name;
                }) !== undefined
                  ? championHealthList.find((champ) => {
                      return champ.name === selectedTopParty.name;
                    }).health
                  : ""}
              </h1>
            </div>
            <div className="bg-black flex flex-col md:flex-row justify-between items-center flex-wrap w-2/3">
              {selectedTopParty !== null &&
              selectedTopParty.name !== "TBA" &&
              championAttributeList.length > 0
                ? championAttributeList
                    .find((champ) => {
                      return champ.name === selectedTopParty.name;
                    })
                    .attributes.slice(0)
                    .reverse()
                    .map((attribute) => {
                      if (
                        attribute.trait_type !== "Ear" &&
                        attribute.trait_type !== "Type"
                      ) {
                        return (
                          <div className="flex flex-col items-center bg-[#A18900] border-white border-4 rounded-md m-2">
                            <h1 className="font-['handwritting'] text-black text-3xl m-2">
                              {attribute.trait_type}
                            </h1>
                            <h1 className="font-['handwritting'] text-black text-3xl m-2">
                              {attribute.value}
                            </h1>
                            {"health" in attribute ? (
                              <h1 className="font-['handwritting'] text-black text-3xl m-2">
                                Added HP: {attribute.health}
                              </h1>
                            ) : null}
                          </div>
                        );
                      } else if (attribute.trait_type === "Type") {
                        return (
                          <div className="flex flex-col items-center bg-[#A18900] border-white border-4 rounded-md m-2">
                            <h1 className="font-['handwritting'] text-black text-3xl m-2">
                              {attribute.trait_type}
                            </h1>
                            <h1 className="font-['handwritting'] text-black text-3xl m-2">
                              {attribute.value}
                            </h1>
                            {"health" in attribute ? (
                              <h1 className="font-['handwritting'] text-black text-3xl m-2">
                                Base HP: {attribute.health}
                              </h1>
                            ) : null}
                          </div>
                        );
                      } else {
                        return null;
                      }
                    })
                : null}
            </div>
          </div>
          <div className="flex flex-row justify-center">
            <h1 className="text-[#FD5839] text-8xl font-['handwritting'] w-1/3">
              VS
            </h1>
          </div>
          <div className="flex flex-col items-center w-1/3">
            <div className="flex flex-col items-center bg-cover bg-center h-[420px] w-[380px] sm:h-[470px] sm:w-[470px] 2xl:h-[780px] 2xl:w-[720px] bg-[url('../media/card.png')] xl:ml-10">
              <img
                src={
                  selectedBottomParty !== null &&
                  Metadata.find((data) => {
                    return data.name === selectedBottomParty.name;
                  }) !== undefined
                    ? Metadata.find((data) => {
                        return data.name === selectedBottomParty.name;
                      }).image
                    : ""
                }
                className="relative top-20 2xl:top-36 h-[40%] w-[40%]"
                alt="their champion"
              ></img>
              <h1 className="relative top-20 2xl:top-36 text-black text-6xl font-['handwritting']">
                {selectedBottomParty !== null ? selectedBottomParty.name : ""}
              </h1>
              <h1 className="relative top-20 2xl:top-36 text-black text-5xl font-['handwritting']">
                Health:{" "}
                {selectedBottomParty !== null &&
                championHealthList.find((champ) => {
                  return champ.name === selectedBottomParty.name;
                }) !== undefined
                  ? championHealthList.find((champ) => {
                      return champ.name === selectedBottomParty.name;
                    }).health
                  : ""}
              </h1>
            </div>
            <div className="bg-black flex flex-col md:flex-row justify-between items-center flex-wrap w-2/3">
              {selectedBottomParty !== null &&
              selectedBottomParty.name !== "TBA" &&
              championAttributeList.length > 0
                ? championAttributeList
                    .find((champ) => {
                      return champ.name === selectedBottomParty.name;
                    })
                    .attributes.slice(0)
                    .reverse()
                    .map((attribute) => {
                      if (
                        attribute.trait_type !== "Ear" &&
                        attribute.trait_type !== "Type"
                      ) {
                        return (
                          <div className="flex flex-col items-center bg-[#A18900] border-white border-4 rounded-md m-2">
                            <h1 className="font-['handwritting'] text-black text-3xl m-2">
                              {attribute.trait_type}
                            </h1>
                            <h1 className="font-['handwritting'] text-black text-3xl m-2">
                              {attribute.value}
                            </h1>
                            {"health" in attribute ? (
                              <h1 className="font-['handwritting'] text-black text-3xl m-2">
                                Added HP: {attribute.health}
                              </h1>
                            ) : null}
                          </div>
                        );
                      } else if (attribute.trait_type === "Type") {
                        return (
                          <div className="flex flex-col items-center bg-[#A18900] border-white border-4 rounded-md m-2">
                            <h1 className="font-['handwritting'] text-black text-3xl m-2">
                              {attribute.trait_type}
                            </h1>
                            <h1 className="font-['handwritting'] text-black text-3xl m-2">
                              {attribute.value}
                            </h1>
                            {"health" in attribute ? (
                              <h1 className="font-['handwritting'] text-black text-3xl m-2">
                                Base HP: {attribute.health}
                              </h1>
                            ) : null}
                          </div>
                        );
                      } else {
                        return null;
                      }
                    })
                : null}
            </div>
          </div>
        </div>
      </Modal>
      <div className="flex flex-col justify-center items-center">
        <img
          src={MyBattles}
          alt="your battles button"
          className="w-3/5 xl:w-1/3 hover:cursor-pointer"
          onClick={() => {
            setInterfaceState("mybattles");
          }}
        ></img>
        <h1 className="text-[#FD5839] text-5xl md:text-5xl font-['handwritting'] mb-5">
          Choose A Bracket
        </h1>
        <Dropdown
          options={bracketHeaders}
          value={bracketHeaders[activeTab]}
          onChange={handleDropdownSelect}
          className="w-1/3 mb-5 bg-[#252525] font-['handwritting'] text-[#FD5839]"
          controlClassName="bg-[#252525] font-['handwritting'] text-[#FD5839] text-4xl flex flex-row justify-center"
          menuClassName="bg-[#252525] font-['handwritting'] text-[#FD5839] text-4xl justify-center"
        />
      </div>
      {activeTab === 0 ? <PlayInList /> : null}
      {activeTab === 1 ? (
        <BracketTab
          matches={bracketOneData}
          isModalOpen={isModalOpen}
          setSelectedBottomParty={setSelectedBottomParty}
          setSelectedTopParty={setSelectedTopParty}
          setIsModalOpen={setIsModalOpen}
        />
      ) : null}
      {activeTab === 2 ? (
        <BracketTab
          matches={bracketTwoData}
          isModalOpen={isModalOpen}
          setSelectedBottomParty={setSelectedBottomParty}
          setSelectedTopParty={setSelectedTopParty}
          setIsModalOpen={setIsModalOpen}
        />
      ) : null}
      {activeTab === 3 ? (
        <BracketTab
          matches={bracketThreeData}
          isModalOpen={isModalOpen}
          setSelectedBottomParty={setSelectedBottomParty}
          setSelectedTopParty={setSelectedTopParty}
          setIsModalOpen={setIsModalOpen}
        />
      ) : null}
      {activeTab === 4 ? (
        <BracketTab
          matches={bracketFourData}
          isModalOpen={isModalOpen}
          setSelectedBottomParty={setSelectedBottomParty}
          setSelectedTopParty={setSelectedTopParty}
          setIsModalOpen={setIsModalOpen}
        />
      ) : null}
      {activeTab === 5 ? (
        <BracketTab
          matches={finalFourData}
          isModalOpen={isModalOpen}
          setSelectedBottomParty={setSelectedBottomParty}
          setSelectedTopParty={setSelectedTopParty}
          setIsModalOpen={setIsModalOpen}
        />
      ) : null}
    </div>
  );
};

export default TournamentBrackets;
