import React, { useEffect, useContext, useState } from "react";
import YourBattles from "../media/your-battles.png";
import NextBattle from "../media/next-round.png";
import Metadata from "../data/metadata.json";
import NextArrow from "../media/next-arrow.svg";
import BracketButton from "../media/bracket-button.png";
import UnstakeChamp from "../media/unstake.png";
import BackArrow from "../media/prev-arrow.svg";
import { ethers } from "ethers";
import { useAccount } from "wagmi";
import tournamentABI from "../contracts/tournamentABI.json";
import championsABI from "../contracts/championsABI.json";
import {
  tournamentContractAddress,
  championsContractAddress,
} from "../contracts/contractAddresses";
import { BracketContext } from "../contexts/BracketContext";
import { PlayInContext } from "../contexts/PlayInContext";
import Dropdown from "react-dropdown";
import MatchResult from "./MatchResult";

const BattleList = ({ setInterfaceState }) => {
  const { address, isConnected } = useAccount();
  const {
    bracketOneData,
    bracketTwoData,
    bracketThreeData,
    bracketFourData,
    finalFourData,
    playInData,
  } = useContext(BracketContext);
  const { roundOneParticipants } = useContext(PlayInContext);
  const [playerMatchList, setPlayerMatchList] = useState([]);
  const [currentToken, setCurrentToken] = useState(null);
  const [battleIndex, setBattleIndex] = useState(0);
  const [stakedTokens, setStakedTokens] = useState([]);
  const [isUnstakeable, setIsUnstakeable] = useState(null);

  const tokenChangeHandler = (option) => {
    setCurrentToken(option);
    setBattleIndex(0);
  };

  const incrementBattleIndex = (increment) => {
    if (
      increment &&
      battleIndex + 1 < playerMatchList[currentToken.value].battles.length
    ) {
      setBattleIndex((index) => (index += 1));
    } else if (!increment && battleIndex - 1 >= 0) {
      setBattleIndex((index) => (index -= 1));
    }
  };

  const unstakeHandler = async () => {
    if (isConnected) {
      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(
          tournamentContractAddress,
          tournamentABI,
          signer
        );
        let tx = await contract.returnChamps();
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    const ableToUnstake = async (signer, staked) => {
      let contract = new ethers.Contract(
        championsContractAddress,
        championsABI,
        signer
      );

      if (staked.length === 0) {
        return false;
      }

      for (let i = 0; i < staked.length; i++) {
        const inArena = await contract.functions.inArena(staked[i]);
        if (inArena[0]) {
          return false;
        }
      }
      return true;
    };

    const createPlayerMatchList = async () => {
      let contract;
      if (isConnected) {
        try {
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const signer = provider.getSigner();
          contract = new ethers.Contract(
            tournamentContractAddress,
            tournamentABI,
            signer
          );
          const allMatches = [
            ...bracketOneData,
            ...bracketTwoData,
            ...bracketThreeData,
            ...bracketFourData,
          ];
          let playerMatches = [];
          let staked = [];
          let i = 0;
          let error = false;
          while (!error) {
            try {
              const champ = await contract.functions.stakedChamps(address, i);
              staked.push(parseInt(champ[0]._hex));
            } catch (err) {
              error = true;
            }
            i++;
          }
          staked.forEach((token, index) => {
            playerMatches.push({
              token: token,
              battles: [],
            });
            for (
              let playInIndex = 0;
              playInIndex < playInData.length;
              playInIndex += 2
            ) {
              if (
                playInData[playInIndex] === token ||
                playInData[playInIndex + 1] === token
              ) {
                playerMatches[index].battles.push({
                  tournamentRoundText: "Play-In Round",
                  matchText: "Play-In Round",
                  participants: [
                    {
                      name:
                        Metadata.find((data) => {
                          return data.id === playInData[playInIndex];
                        }) !== undefined
                          ? Metadata.find((data) => {
                              return data.id === playInData[playInIndex];
                            }).name
                          : null,
                      isWinner:
                        roundOneParticipants.length > 0
                          ? roundOneParticipants.includes(
                              playInData[playInIndex]
                            )
                          : roundOneParticipants.length === 0
                          ? null
                          : false,
                      token: `Token #${playInData[playInIndex]}`,
                    },
                    {
                      name:
                        Metadata.find((data) => {
                          return data.id === playInData[playInIndex + 1];
                        }) !== undefined
                          ? Metadata.find((data) => {
                              return data.id === playInData[playInIndex + 1];
                            }).name
                          : null,
                      isWinner:
                        roundOneParticipants.length > 0
                          ? roundOneParticipants.includes(
                              playInData[playInIndex + 1]
                            )
                          : roundOneParticipants.length === 0
                          ? null
                          : false,
                      token: `Token #${playInData[playInIndex + 1]}`,
                    },
                  ],
                });
              }
            }
            playerMatches[index].battles = playerMatches[index].battles.concat(
              allMatches.filter((match) => {
                return match.participants.find((participant) => {
                  return participant.token === `Token #${token}`;
                });
              })
            );
            playerMatches[index].battles = playerMatches[index].battles.concat(
              finalFourData.filter((match) => {
                return match.participants.find((participant) => {
                  return participant.token === `Token #${token}`;
                });
              })
            );
            setPlayerMatchList(playerMatches);
          });
          setIsUnstakeable(await ableToUnstake(signer, staked));
          setStakedTokens(staked);
        } catch (err) {
          console.log(err);
          console.error("Error initializing contract");
        }
      }
    };
    createPlayerMatchList();
  }, []);

  return (
    <div className="bg-black z-50 w-screen h-fit pb-24">
      <div className="flex flex-col items-center">
        <div className="flex flex-col items-center xl:flex-row xl:justify-between">
          <img
            src={BracketButton}
            alt="go back to tournament brackets button"
            className="w-1/2 xl:w-1/5 hover:cursor-pointer"
            onClick={() => setInterfaceState("tournament")}
          ></img>
          <img
            src={YourBattles}
            alt="your battles icon"
            className="w-1/2 xl:w-1/3 mb-10"
          ></img>
          {isUnstakeable ? (
            <img
              src={UnstakeChamp}
              alt="unstake champ button"
              className="w-1/2 xl:w-1/5 mb-10 mt-10 hover:cursor-pointer"
              onClick={unstakeHandler}
            ></img>
          ) : (
            <div className="w-1/5"></div>
          )}
        </div>
        <div className="flex flex-row justify-center">
          {currentToken !== null ? (
            <img
              src={BackArrow}
              alt="previous arrow"
              className="hover:cursor-pointer"
              onClick={() => {
                incrementBattleIndex(false);
              }}
            ></img>
          ) : null}
          <img
            src={NextBattle}
            alt="next battle icon"
            className="w-3/5 xl:w-1/5 mb-10"
          ></img>
          {currentToken !== null ? (
            <img
              src={NextArrow}
              alt="next arrow"
              className="hover:cursor-pointer"
              onClick={() => {
                incrementBattleIndex(true);
              }}
            ></img>
          ) : null}
        </div>
        <Dropdown
          options={playerMatchList.map((tokenMatches, index) => {
            return {
              value: index,
              label:
                Metadata.find((data) => {
                  return data.id === tokenMatches.token;
                }) !== undefined
                  ? Metadata.find((data) => {
                      return data.id === tokenMatches.token;
                    }).name
                  : null,
            };
          })}
          onChange={tokenChangeHandler}
          placeholder="Choose a token to view matches"
          className="w-1/3 mb-5 bg-[#252525] font-['handwritting'] text-[#FD5839]"
          controlClassName="bg-[#252525] font-['handwritting'] text-[#FD5839] text-4xl flex flex-row justify-center"
          menuClassName="bg-[#252525] font-['handwritting'] text-[#FD5839] text-4xl justify-center"
        />
        {currentToken !== null &&
        playerMatchList[currentToken.value].battles.length > 0 ? (
          <MatchResult
            myTokenNumber={playerMatchList[currentToken.value].token}
            opponentTokenNumber={parseInt(
              playerMatchList[currentToken.value].battles[
                battleIndex
              ].participants
                .filter((participant) => {
                  return (
                    parseInt(participant.token.split("#")[1]) !==
                    playerMatchList[currentToken.value].token
                  );
                })[0]
                .token.split("#")[1]
            )}
            matchParticipantData={
              playerMatchList[currentToken.value].battles[battleIndex]
                .participants
            }
            matchTitle={
              playerMatchList[currentToken.value].battles[battleIndex].matchText
            }
          />
        ) : null}
      </div>
    </div>
  );
};

export default BattleList;
