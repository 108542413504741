import React, { useEffect, useState } from "react";
import Metadata from "../data/metadata.json";
import AttributeData from "../data/attribute-values.json";

export const HealthContext = React.createContext();

const HealthContextProvider = ({ children }) => {
  const [championHealthList, setChampionHealthList] = useState([]);
  const [championAttributeList, setChampionAttributeList] = useState([]);

  useEffect(() => {
    let healthList = [];
    let attributeList = [];

    Metadata.map((champ) => {
      let health = 0;
      let champType = "";
      healthList.push({
        id: champ.id,
        name: champ.name,
      });
      attributeList.push({
        id: champ.id,
        name: champ.name,
        attributes: champ.attributes,
      });

      if (champ.id < 5000) {
        health += 13;
        champType = "Rabid";
      } else if (champ.id > 5000 && champ.id < 7000) {
        health += 20;
        champType = "Super";
      } else {
        champType = "Noble Knight";
      }

      champ.attributes.map((attribute, index) => {
        if (
          attribute.trait_type in AttributeData[champType] &&
          attribute.value in AttributeData[champType][attribute.trait_type]
        ) {
          health +=
            AttributeData[champType][attribute.trait_type][attribute.value];
          attributeList[attributeList.length - 1].attributes[index] = {
            ...attributeList[attributeList.length - 1].attributes[index],
            health:
              AttributeData[champType][attribute.trait_type][attribute.value],
          };
        }
      });
      if (champType === "Noble Knight") {
        health += AttributeData[champType]["name"][champ.name];
        attributeList[attributeList.length - 1].attributes.push({
          trait_type: "Name",
          value: champ.name,
          health: AttributeData[champType]["name"][champ.name],
        });
      } else {
        attributeList[attributeList.length - 1].attributes.push({
          trait_type: "Type",
          value: champType,
          health: champType === "Rabid" ? 13 : 20,
        });
      }
      healthList[healthList.length - 1]["health"] = health;
    });
    setChampionHealthList(healthList);
    setChampionAttributeList(attributeList);
  }, []);

  return (
    <HealthContext.Provider
      value={{
        championHealthList,
        championAttributeList,
      }}
    >
      {children}
    </HealthContext.Provider>
  );
};

export default HealthContextProvider;
