import './scss/Item.scss';

function Item({ image, visible, id }) {

  return (
    <div className={"item " + visible}>
      <img src={image} id={id} />
    </div>
  )
}

export default Item;
