import { useEffect } from 'react';
import axios from 'axios';
import './scss/Mint.scss';
import eating from './media/eating.gif';

function MintChampion({
  txnObj,
  championsContract,
  setChamp,
  setInterfaceState
}) {

  useEffect(() => {
    console.log(txnObj)
    // get champion id & signature
    axios.get('https://5nefulkewl5yaytafb6qd3w5ze0gxiry.lambda-url.us-east-2.on.aws/', {
      params: txnObj
    })
    .then(function (response) {
      console.log('champs data:', response.data);
      const championId = response.data.champ;
      const signature = response.data.signature;
      mintChamp(championId, signature);
    })
    .catch(function (error) {
      console.log(error);
    })
    .finally(function () {
      // always executed
    });
  }, [])

  const mintChamp = async (championId, signature) => {
    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    await delay(30 * 1000);

    const { howler, food } = txnObj
    let estimatedGas

    try {
      console.log('estimating gas...')
      if (txnObj.foodstatus === 0) {
        estimatedGas = await championsContract.estimateGas.mint(howler, food, championId, signature)
      } else {
        console.log('feeding howler to howler');
        estimatedGas = await championsContract.estimateGas.feedHowler(howler, food, championId, signature)
      }

    } catch (err) {
      console.log(err)
      alert(err.message)
    }

    // Add 50,000 to prevent failures from bad gas limit estimate.
    const gasLimit = estimatedGas.add(20000)

    try {
      console.log('minting...')
      console.log(txnObj)
      // if normal, use mint function
      if (txnObj.foodstatus === 0) {
        await championsContract.mint(howler, food, championId, signature, { gasLimit });
      // if feeling howler to howler, use feedHowler function
      } else {
        console.log('feeding howler to howler');
        await championsContract.feedHowler(howler, food, championId, signature, { gasLimit });
      }
      console.log('minted')
    } catch (err) {
      alert('There was an issue minting.')
      return;
    }

    try {
      console.log('getting champ...')
      const resp = await fetch(`https://howlerz-metadata2.s3.us-east-2.amazonaws.com/${championId}`);
      const json = await resp.json();
      setChamp(json)
    } catch (err) {
      alert('There was an issue getting your champ')
      return;
    }

    setInterfaceState('champion')
  }

  return (
    <div id="mint-container" className="wrapper">
      <img src={eating} />
    </div>
  )
}

export default MintChampion;
