import React, { useContext, useEffect, useState } from "react";
import { BracketContext } from "../contexts/BracketContext";
import { PlayInContext } from "../contexts/PlayInContext";
import Metadata from "../data/metadata.json";

const PlayInList = () => {
  const { playInData } = useContext(BracketContext);
  const [matches, setMatches] = useState([]);
  const { roundOneParticipants } = useContext(PlayInContext);
  useEffect(() => {
    let matchList = [];
    for (
      let playInIndex = 0;
      playInIndex < playInData.length;
      playInIndex += 2
    ) {
      if (roundOneParticipants.length > 0) {
        roundOneParticipants.includes(playInData[playInIndex])
          ? matchList.push([
              playInData[playInIndex],
              playInData[playInIndex + 1],
            ])
          : matchList.push([
              playInData[playInIndex + 1],
              playInData[playInIndex],
            ]);
      } else {
        matchList.push([playInData[playInIndex], playInData[playInIndex + 1]]);
      }
    }
    setMatches(matchList);
  }, []);

  return (
    <div className="flex flex-col overflow-scroll h-[500px] mb-64 rounded-md border-2 border-sky-800 h-1/3">
      {matches.length !== 0 ? (
        matches.map((match) => {
          return (
            <div className="flex flex-row justify-between pl-10 pr-10 md:pl-16 md:pr-16 xl:pl-24 xl:pr-24">
              <h1 className="flex flex-row justify-end text-blue-500 text-2xl sm:text-4xl md:text-5xl font-['handwritting'] w-1/3">
                {Metadata.find((data) => {
                  return data.id === match[0];
                }) !== undefined
                  ? Metadata.find((data) => {
                      return data.id === match[0];
                    }).name
                  : null}
              </h1>
              <h1 className="flex flex-row justify-center text-[#FD5839] text-2xl sm:text-3xl md:text-5xl font-['handwritting'] w-1/3">
                {roundOneParticipants.length > 0 ? "Defeated" : "VS"}
              </h1>
              <h1 className="text-rose-500 text-2xl sm:text-3xl md:text-5xl font-['handwritting'] w-1/3">
                {Metadata.find((data) => {
                  return data.id === match[1];
                }) !== undefined
                  ? Metadata.find((data) => {
                      return data.id === match[1];
                    }).name
                  : null}
              </h1>
            </div>
          );
        })
      ) : (
        <div className="flex flex-row justify-center">
          <h1 className="text-[#FD5839] text-3xl sm:text-5xl font-['handwritting']">
            No Match Data Found!
          </h1>
        </div>
      )}
    </div>
  );
};

export default PlayInList;
