import './scss/Staked.scss';
import staked from './media/staked.png';
import stakedDisclaimer from './media/staked-disclaimer.png';

function Staked() {

  return (
    <div id="staked" className="wrapper">
      <img src={staked} />
      <img src={stakedDisclaimer} />
    </div>
  )
}

export default Staked;
