import { useEffect } from 'react';
import './scss/CheckForHowlerz.scss';
import checkingForChampions from './media/checkingForChampions.png';

function CheckForChampions({
  address,
  championsContract,
  setAllChampions,
  setInterfaceState
}) {

  useEffect(() => {
    checkForChampions()
    console.log(championsContract.address)
  }, []);


  // lookup tokens for connected account (using Moralis)
  const checkForChampions = async () => {
    const options = {
      method: 'GET',
      headers: {
        accept: 'application/json',
        'X-API-KEY': process.env.REACT_APP_SIMPLEHASH
      }
    };

    fetch(`https://api.simplehash.com/api/v0/nfts/owners?chains=ethereum&wallet_addresses=${address}&contract_addresses=${championsContract.address}&limit=50`, options)
      .then(response => response.json())
      .then(response => {
        if (response.nfts.length > 0) {
          console.log('has champions', response.nfts)
          setAllChampions(response.nfts)
          console.log('proceed to select & set approval...')
          setInterfaceState('setChampionsApproval')
        } else {
          alert('You need a Champion to stake. Disconnect this account then refresh the site to try again with a different account.')
        }
      })
      .catch(err => {
        console.error(err)
      });
  }

  return (
    <div id="checking-container" className="wrapper">
      <img src={checkingForChampions} />
    </div>
  )
}

export default CheckForChampions;
