// Styles
import "./scss/BurnOrStake.scss";
// Media
import burn from "./media/burn.png";
import or from "./media/or.png";
import enter from "./media/enter-the-tournament.png";

function BurnOrStake({ setInterfaceState }) {
  return (
    <div id="burn-or-stake" className="wrapper">
      <button
        id="burn"
        onClick={() => {
          setInterfaceState("checkForHowlerz");
        }}
      >
        <img src={burn} />
      </button>

      <img
        className="md:w-[100px] w-[80px] relative md:left-36 left-24"
        src={or}
      />

      <button
        id="stake"
        className=""
        onClick={() => {
          setInterfaceState("tournament");
          document.getElementById("tournamentAudio").play();
        }}
      >
        <img src={enter} className="p-50" />
      </button>
    </div>
  );
}

export default BurnOrStake;
