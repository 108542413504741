import logo from "./media/logo.png";
import selectChamps from "./media/select-champs.png";
import twitter from "./media/twitter.svg";
import discord from "./media/discord.svg";
import "./scss/Header.scss";

function Header({ img }) {
  return (
    <>
      <header>
        <div className="w-[35%]"></div>
        <a
          id="logo"
          className={`${img === null ? "null" : "stake"}`}
          href="/"
        ></a>
        <div className="w-[35%] flex flex-col items-end md:justify-end md:flex-row">
          <a
            href="https://twitter.com/HOWLERZNFT"
            className="w-[80%] md:w-[35%] md:mr-8 md:mb-0 mb-8"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={twitter} className="mr-8" alt="twitter" />
          </a>
        </div>
      </header>
    </>
  );
}

export default Header;
