import { useEffect } from 'react';
import './scss/StakeChampions.scss';
import staking from './media/staking.gif';

function StakeChampions({
  championsToStakeGlobal,
  tournamentContract,
  setInterfaceState
}) {

  useEffect(() => {
    window.scrollTo(0,0)
    stakeChampions()
  }, [])

  const stakeChampions = async () => {
    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    await delay(30 * 1000);

    let estimatedGas

    try {
      console.log('estimating gas...')
      estimatedGas = await tournamentContract.estimateGas.stakeChamps(championsToStakeGlobal)

    } catch (err) {
      console.log(err)
      alert(err.message)
    }

    // Add 50,000 to prevent failures from bad gas limit estimate.
    const gasLimit = estimatedGas.add(20000)

    try {
      console.log('staking...')
      await tournamentContract.stakeChamps(championsToStakeGlobal, { gasLimit });
      console.log('staked!')
    } catch (err) {
      alert('There was an issue staking your champions')
      return;
    }

    setInterfaceState('staked')
  }

  return (
    <div id="stakeChampions" className="wrapper">
      <img src={staking} />
    </div>
  )
}

export default StakeChampions;
