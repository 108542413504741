import React, { createContext, useEffect, useState } from "react";
import axios from "axios";

export const PlayInContext = React.createContext();

const PlayInContextProvider = ({ children }) => {
  const [roundOneParticipants, setRoundOneParticipants] = useState([]);

  useEffect(() => {
    const findRoundOneParticipants = async () => {
      let participantList = [];
      try {
        const res = await axios.get("/.netlify/functions/tournament-data");
        if (res.data.roundData.length > 0) {
          res.data.roundData[0]["round1"].forEach((bracket) => {
            for (
              let participantIndex = 0;
              participantIndex < bracket.length;
              participantIndex++
            ) {
              participantList.push(bracket[participantIndex]);
            }
          });
        }
        setRoundOneParticipants(participantList);
      } catch (err) {
        console.log(err);
      }
    };
    findRoundOneParticipants();
  }, []);

  return (
    <PlayInContext.Provider
      value={{
        roundOneParticipants,
      }}
    >
      {children}
    </PlayInContext.Provider>
  );
};

export default PlayInContextProvider;
